// import vis from "visjs-network"
import { DataSet, Network } from "vis-network/standalone";
import axios from 'axios';

// import { Network } from 'vis-network/standalone';

// import Network from "react-graph-vis";
// import Graph from "../../lib";
// import Graph from 'react-graph-vis'
// import React from "react";
// import React, {useState, useEffect} from 'react';
// import { render } from "react-dom";


/**
 *     1) todo: different edge set for different subgraphs
 *          the strange address before I and D
 *     2) todo: if a same address is curator + indexer <-- handle this case
 *     3) todo: when delegator/indexer>1000 or #ofindexer>1000 or #of curator>1000 or #of subgraphs>1000 --> write two query using first and skip
 */
const baseURL = "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-mainnet";

async function getIndexerStats() {
    const query = `
{
  indexers(
    first: 1000) {
     defaultDisplayName
    id
       indexingRewardCut
    queryFeeCut
        stakedTokens
    delegatedTokens
    delegators (first: 1000) {
      delegator {
        id
        totalStakedTokens
        totalUnstakedTokens
        totalRealizedRewards
        
      }
    }
  }
}
  `;
    const res = await axios.post(baseURL, {query});
    return res.data;
}

async function getSubgraphStats1() {
    const query = `
{
  subgraphDeployments(first:1000) {
    originalName
    stakedTokens
    queryFeesAmount
    signalledTokens
    indexingIndexerRewardAmount
    indexingDelegatorRewardAmount
    indexerAllocations(first:1000){
      indexer{
        id
      }
    }
    curatorSignals (first:1000) {
      curator{
        id
        totalSignalledTokens
        totalUnsignalledTokens
        realizedRewards
        
      }
    }
  }
}

  `;

    const res = await axios.post(baseURL, {query});
    return res.data;
}


async function getSubgraphStats2() {

    const query = `
{
  subgraphDeployments(first:1000) {
    originalName
    stakedTokens
    queryFeesAmount
    signalledTokens
    indexingIndexerRewardAmount
    indexingDelegatorRewardAmount
    indexerAllocations(first:1000 skip: 1000){
      indexer{
        id
      }
    }
    curatorSignals (first:1000 skip: 1000) {
      curator{
        id
        totalSignalledTokens
        totalUnsignalledTokens
        realizedRewards
        
      }
    }
  }
}


  `;
    const res = await axios.post(baseURL, {query});
    return res.data;
}

async function loadIndexerData() {
    const indexerStats = await getIndexerStats();
    return indexerStats.data.indexers;
}


async function loadSubgraphData1() {
    const subgraphStats = await getSubgraphStats1();
    return subgraphStats.data.subgraphDeployments;
}

async function loadSubgraphData2() {
    const subgraphStats = await getSubgraphStats2();
    return subgraphStats.data.subgraphDeployments;
}

async function draw() {
    // create some nodes
    var gNodes = [];
    var gEdges = [];
    //
    // gNodes.push({
    //     id: "pooltogether",
    //     label: "pooltogether",
    //     group: "subgraph"
    // });
    let totalNumberOfDelegators = 0;
    let totalNumberOfIndexers = 0;
    const indexerStats = await (loadIndexerData());
    let set = new Set();
    for (const dataObj of indexerStats) {

        const indexingRewardCut = parseFloat(dataObj.indexingRewardCut) / Math.pow(10, 4).toFixed(2);
        const queryFeeCut = parseFloat(dataObj.queryFeeCut) / Math.pow(10, 4).toFixed(2);
        const indexerStakedTokens = parseFloat(((dataObj.stakedTokens) / Math.pow(10, 18)).toFixed(2));
        const delegatedTokens = parseFloat(((dataObj.delegatedTokens) / Math.pow(10, 18)).toFixed(2));
        const address = String(dataObj.id);
        var shortAddress = address.substring(0, 6);
        shortAddress =shortAddress + "-";
        shortAddress = shortAddress + address.substring(36, 42);


        let isOverdelagted = false;
        const delegationRatio = delegatedTokens / indexerStakedTokens;
        if (delegationRatio > 16) {
            isOverdelagted = true;
        }

        let countOfDelegatorsPerIndexer = 0;
        totalNumberOfIndexers++;
        gNodes.push({
            id: "I-" + dataObj.id,
            label: dataObj.id,
            shape: "database",
            title: `<b>Role: </b>Indexer<br>` +
                `<b>Address: </b>${shortAddress}<br>` +
                `<b>Rewards Cut: </b>${indexingRewardCut}<br>` +
                `<b>Query Cut: </b>${queryFeeCut}<br>` +
                `<b>Indexer Staked Tokens: </b>${indexerStakedTokens}<br>` +
                `<b>Delegated Token: </b>${delegatedTokens}<br>` +
                `<b>Is Overdelegated: </b>${isOverdelagted}<br>` ,
            scaling: {
                label: {
                    enabled: true,
                    min: 5,
                    max: 5
                }
            },
            value: 0.01,
            group: "indexer"
        });
        set.add((dataObj.id));
        // gEdges.push({
        //     from: "pooltogether", to: "I-"+dataObj.id
        // })
        //  console.log(dataObj.id);

        // console.log({
        //     id: dataObj.id,
        //     label: dataObj.id,
        //     group: "indexer"
        // });
        for (const delegator of dataObj.delegators) {

            const totalStakedTokens = parseFloat(((delegator.delegator.totalStakedTokens) / Math.pow(10, 18)).toFixed(2));
            const totalUnstakedTokens = parseFloat(((delegator.delegator.totalUnstakedTokens) / Math.pow(10, 18)).toFixed(2));
            const totalRealizedRewards = parseFloat(((delegator.delegator.totalRealizedRewards) / Math.pow(10, 18)).toFixed(2));
            const address = String(delegator.delegator.id);
            var shortAddress_delegator = address.substring(0, 6);
            shortAddress_delegator =shortAddress_delegator + "-";
            shortAddress_delegator = shortAddress_delegator + address.substring(36, 42);






            if (!set.has((delegator.delegator.id))) {
                gNodes.push({
                    id: "D-" + delegator.delegator.id,
                    label: delegator.delegator.id,
                    title: `<b>Role: </b>Delegator<br>` +
                        `<b>Address: </b>${shortAddress_delegator}<br>` +
                        `<b>Total Staked Tokens: </b>${totalStakedTokens}<br>` +
                        `<b>Total Unstaked Tokens: </b>${totalUnstakedTokens}<br>` +
                        `<b>Total Realized Rewards: </b>${totalRealizedRewards}<br>`,
                    group: "delegator",
                    shape: "circle",
                    scaling: {
                        label: {
                            enabled: true,
                            min: 2,
                            max: 2
                        }
                    },
                    value: 0.01
                    // borderWidth: 2,
                    // border: "black",
                    // borderColor: "black"
                });
                set.add((delegator.delegator.id));
            }
            gEdges.push({
                from: "I-" + dataObj.id, to: "D-" + delegator.delegator.id,
                color: {
                    color: "#DA70D6"
                }
            })
            // console.log(delegator.delegator.id);
            //0x4bbfbd1320093858d877ab0c8cd91ef0ce065318

            countOfDelegatorsPerIndexer++;
        }
        totalNumberOfDelegators = totalNumberOfDelegators + countOfDelegatorsPerIndexer;
        //  console.log((dataObj.id), countOfDelegatorsPerIndexer);

    }
    // console.log(totalNumberOfIndexers, totalNumberOfDelegators)
    // console.log(gNodes);
    const subgraphStats1 = await (loadSubgraphData1());
    const subgraphStats2 = await (loadSubgraphData2());

    /**
     * subgraphStats has:
     * 1) originalName
     * 2) arr of curatorSignals
     * 3) arr of indexerAllocations
     */

        //   let edgeSet = new Set();

    let map = new Map();
    for (const subgraphStat of subgraphStats1) {



        const queryFeesAmount = parseFloat(((subgraphStat.queryFeesAmount) / Math.pow(10, 18)).toFixed(2));
        const signalledTokens = parseFloat(((subgraphStat.signalledTokens) / Math.pow(10, 18)).toFixed(2));
        const stakedTokens = parseFloat(((subgraphStat.stakedTokens) / Math.pow(10, 18)).toFixed(2));
        const indexingDelegatorRewardAmount = parseFloat(((subgraphStat.indexingDelegatorRewardAmount) / Math.pow(10, 18)).toFixed(2));
        const indexingIndexerRewardAmount = parseFloat(((subgraphStat.indexingIndexerRewardAmount) / Math.pow(10, 18)).toFixed(2));
        const name = String(subgraphStat.originalName);



        if (!map.has(subgraphStat.originalName)) {
            map.set(subgraphStat.originalName, new Set())
        }
        let edgeSet = map.get(subgraphStat.originalName);
        if (!set.has(subgraphStat.originalName)) {
            gNodes.push({
                id: "S-" + subgraphStat.originalName,
                label: subgraphStat.originalName,
                title: `<b>Role: </b>Subgraph<br>` +
                    `<b>Subgraph Name: </b>${name}<br>` +
                    `<b>Staked Tokens: </b>${stakedTokens}<br>` +
                    `<b>Signalled Tokens: </b>${signalledTokens}<br>` +
                    `<b>Query Fees Amount: </b>${queryFeesAmount}<br>` +
                    `<b>Indexer Reward Amount: </b>${indexingIndexerRewardAmount}<br>` +
                    `<b>Delegator Reward Amount: </b>${indexingDelegatorRewardAmount}<br>`,
                group: "subgraph",
                shape: "ellipse",
                scaling: {
                    label: {
                        enabled: true,
                        min: 50,
                        max: 50
                    }
                },
                value: 0.01
                // title: "odnsnnsnsnnsns"
            });
            set.add(subgraphStat.originalName)
        }

        for (const indexer of subgraphStat.indexerAllocations) {
            if (!set.has(indexer.indexer.id)) {
                gNodes.push({
                    id: "I-" + indexer.indexer.id,
                    label: indexer.indexer.id,
                    group: "indexer",
                    shape: "database",
                    scaling: {
                        label: {
                            enabled: true,
                            min: 5,
                            max: 5
                        }
                    },
                    value: 0.01
                });
                set.add(indexer.indexer.id)
            }
            if (!edgeSet.has("I-" + indexer.indexer.id)) {
                gEdges.push({
                    from: "S-" + subgraphStat.originalName, to: "I-" + indexer.indexer.id,
                    color: {
                        color: "green"
                    },
                    width: 5

                })
                edgeSet.add("I-" + indexer.indexer.id)
            }
        }
//0x8b7663dd451c951f39e541188d9f7d9419e94421 not have any allocation
        for (const curator of subgraphStat.curatorSignals) {


            const totalSignalledTokens = parseFloat(((curator.curator.totalSignalledTokens) / Math.pow(10, 18)).toFixed(2));
            const totalUnsignalledTokens = parseFloat(((curator.curator.totalUnsignalledTokens) / Math.pow(10, 18)).toFixed(2));
            const realizedRewards = parseFloat(((curator.curator.realizedRewards) / Math.pow(10, 18)).toFixed(2));
            const address = String(curator.curator.id);
            var shortAddress_curator = address.substring(0, 6);
            shortAddress_curator =shortAddress_curator + "-";
            shortAddress_curator = shortAddress_curator + address.substring(36, 42);


            if (!set.has(curator.curator.id)) {
                gNodes.push({
                    id: "C-" + curator.curator.id,
                    label: curator.curator.id,
                    title: `<b>Role: </b>Curator<br>` +
                        `<b>Address: </b>${shortAddress_curator}<br>` +
                        `<b>Total Signalled Tokens: </b>${totalSignalledTokens}<br>` +
                        `<b>Total Unsignalled Tokens: </b>${totalUnsignalledTokens}<br>` +
                        `<b>Realized Rewards: </b>${realizedRewards}<br>`,
                    group: "curator",
                    shape: "circle",
                    scaling: {
                        label: {
                            enabled: true,
                            min: 5,
                            max: 5
                        }
                    },
                    value: 0.01
                });
                set.add(curator.curator.id)
            }

            gEdges.push({
                from:"C-" + curator.curator.id, to: "S-" + subgraphStat.originalName,
                color: {
                    color: "green"
                },
                width: 5

            })
        }
    }


    for (const subgraphStat of subgraphStats2) {

        const queryFeesAmount = parseFloat(((subgraphStat.queryFeesAmount) / Math.pow(10, 18)).toFixed(2));
        const signalledTokens = parseFloat(((subgraphStat.signalledTokens) / Math.pow(10, 18)).toFixed(2));
        const stakedTokens = parseFloat(((subgraphStat.stakedTokens) / Math.pow(10, 18)).toFixed(2));
        const indexingDelegatorRewardAmount = parseFloat(((subgraphStat.indexingDelegatorRewardAmount) / Math.pow(10, 18)).toFixed(2));
        const indexingIndexerRewardAmount = parseFloat(((subgraphStat.indexingIndexerRewardAmount) / Math.pow(10, 18)).toFixed(2));
        const name = String(subgraphStat.originalName);


        if (!map.has(subgraphStat.originalName)) {
            map.set(subgraphStat.originalName, new Set())
        }
        let edgeSet = map.get(subgraphStat.originalName);
        if (!set.has(subgraphStat.originalName)) {
            gNodes.push({
                id: "S-" + subgraphStat.originalName,
                label: subgraphStat.originalName,
                title: `<b>Role: </b>Subgraph<br>` +
                    `<b>Subgraph Name: </b>${name}<br>` +
                    `<b>Staked Tokens: </b>${stakedTokens}<br>` +
                    `<b>Signalled Tokens: </b>${signalledTokens}<br>` +
                    `<b>Query Fees Amount: </b>${queryFeesAmount}<br>` +
                    `<b>Indexer Reward Amount: </b>${indexingIndexerRewardAmount}<br>` +
                    `<b>Delegator Reward Amount: </b>${indexingDelegatorRewardAmount}<br>`,
                group: "subgraph",
                shape: "ellipse",
                scaling: {
                    label: {
                        enabled: true,
                        min: 20,
                        max: 20
                    }
                },
                value: 0.01
            });
            set.add(subgraphStat.originalName)
        }

        for (const indexer of subgraphStat.indexerAllocations) {
            if (!set.has(indexer.indexer.id)) {
                gNodes.push({
                    id: "I-" + indexer.indexer.id,
                    label: indexer.indexer.id,
                    group: "indexer",
                    shape: "database",
                    scaling: {
                        label: {
                            enabled: true,
                            min: 5,
                            max: 5
                        }
                    },
                    value: 0.01
                });
                set.add(indexer.indexer.id)
            }
            if (!edgeSet.has("I-" + indexer.indexer.id)) {
                gEdges.push({
                    from: "S-" + subgraphStat.originalName, to: "I-" + indexer.indexer.id,
                    color: {
                        color: "green"
                    },
                    width: 5
                })
                edgeSet.add("I-" + indexer.indexer.id)
            }
        }

        for (const curator of subgraphStat.curatorSignals) {



            const totalSignalledTokens = parseFloat(((curator.curator.totalSignalledTokens) / Math.pow(10, 18)).toFixed(2));
            const totalUnsignalledTokens = parseFloat(((curator.curator.totalUnsignalledTokens) / Math.pow(10, 18)).toFixed(2));
            const realizedRewards = parseFloat(((curator.curator.realizedRewards) / Math.pow(10, 18)).toFixed(2));
            const address = String(curator.curator.id);
            var shortAddress_curator_2 = address.substring(0, 6);
            shortAddress_curator_2 =shortAddress_curator_2 + "-";
            shortAddress_curator_2 = shortAddress_curator_2 + address.substring(36, 42);


            if (!set.has(curator.curator.id)) {
                gNodes.push({
                    id: "C-" + curator.curator.id,
                    label: curator.curator.id,
                    title: `<b>Role: </b>Curator<br>` +
                        `<b>Address: </b>${shortAddress_curator_2}<br>` +
                        `<b>Total Signalled Tokens: </b>${totalSignalledTokens}<br>` +
                        `<b>Total Unsignalled Tokens: </b>${totalUnsignalledTokens}<br>` +
                        `<b>Realized Rewards: </b>${realizedRewards}<br>`,
                    group: "curator",
                    shape: "circle",
                    scaling: {
                        label: {
                            enabled: true,
                            min: 5,
                            max: 5
                        }
                    },
                    value: 0.01
                });
                set.add(curator.curator.id)
            }

            gEdges.push({
                from: "S-" + subgraphStat.originalName, to: "C-" + curator.curator.id,
                color: {
                    color: "green"
                },
                width: 5
            })
        }
    }


    var nodes = [
        {id: 0, label: "Myriel", group: 1},
        {id: 76, label: "Mme.Hucheloup", group: 8},
    ];

    // create some edges
    var edges = [
        {from: 1, to: 0},
        {from: 76, to: 58},
    ];

    // create a network
    var container = document.getElementById("mynetwork");
    var data = {
        nodes: gNodes,
        edges: gEdges,
    };
    var options = {
        edges: {
            color : {
                inherit: false
            }
        },
        interaction: { hover: true,
            tooltipDelay: 0,
        navigationButtons: false},
        manipulation: {
            enabled: false,
        },
        layout: {improvedLayout: false},
        nodes: {
            shape: "dot"
            // shapeProperties: {
            //     interpolation: false    // 'true' for intensive zooming
            // }
            // size: 16
        },
        physics: {
            forceAtlas2Based: {
                gravitationalConstant: -26,
                centralGravity: 0.005,
                springLength: 230,
                springConstant: 0.18,
            },
            maxVelocity: 146,
            solver: "forceAtlas2Based",
            timestep: 0.35,
            stabilization: {
                enabled: true,
                iterations: 170,
                updateInterval: 1,
            },
        }



    };
    // eslint-disable-next-line no-unused-vars
    var network = new Network(container, data, options);
    // network.moveTo({     scale: 1.515});
    // network.setOptions({ edges: { smooth: { enabled: false, type: "continuous" } } });
    // network.setOptions({
    //     position: { x: 866, y: 401 },
    //     scale: 0.1342000585922003,
    //     // offset: { x: offsetx, y: offsety },
    //     animation: true, // default duration is 1000ms and default easingFunction is easeInOutQuad.
    // });


    // {
    //     "direction": "+",
    //     "scale": 0.1342000585922003,
    //     "pointer": {
    //     "x": 866,
    //         "y": 401.5625
    // }
    // }







    network.on("stabilizationProgress", function (params) {
        var maxWidth = 496;
        var minWidth = 20;
        console.log("params.iterations",params.iterations)
        var widthFactor = params.iterations / params.total;
        var width = Math.max(minWidth, maxWidth * widthFactor);

        document.getElementById("bar").style.width = width + "px";
        document.getElementById("text").innerText = Math.round(widthFactor * 100) + "%";
    });
    network.once("stabilizationIterationsDone", function () {
        network.moveTo({     scale: 0.100});
        document.getElementById("text").innerText = "100%";
        document.getElementById("bar").style.width = "496px";
        document.getElementById("loadingBar").style.opacity = 0;
        // really clean the dom element
        setTimeout(function () {
            document.getElementById("loadingBar").style.display = "none";
        }, 500);
    });



    network.on("click", function (params) {


        if (params.nodes.length === 1) {
            console.log("node Id",params.nodes[0])
            var nodeIdWithType = params.nodes[0];
            var nodeId = nodeIdWithType.substring(2)
            var values = {"%ID%":`${nodeId}`};


            var url = "";

            if (nodeIdWithType.startsWith("I")) {
                url = "https://network.thegraph.com/profile?id=%ID%&view=Indexing"

            } else if (nodeIdWithType.startsWith("C")) {
                url = "https://network.thegraph.com/profile?id=%ID%&view=Overview"

            }
            else if (nodeIdWithType.startsWith("D")) {
                url = "https://network.thegraph.com/profile?id=%ID%&view=Delegating"

            } else if (nodeIdWithType.startsWith("S")) {
                return;
            }
            else {
                return;
            }
            url = url.replace("%ID%",values["%ID%"])
            console.log("url", url);
            window.open(url, '_blank');
        }













        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText = "Click event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
        // console.log(
        //     "click event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
        // );
    });
    network.on("doubleClick", function (params) {
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText = "doubleClick event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
    });
    network.on("oncontext", function (params) {
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText =
        //     "oncontext (right click) event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
    });
    network.on("dragStart", function (params) {
        // There's no point in displaying this event on screen, it gets immediately overwritten
        network.canvas.body.container.style.cursor = 'grabbing';
        // params.event = "[original event]";
        // console.log("dragStart Event:", params);
        // console.log(
        //     "dragStart event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
        // );
    });
    network.on("dragging", function (params) {
        network.canvas.body.container.style.cursor = 'grabbing';
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText = "dragging event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
    });
    network.on("dragEnd", function (params) {
        network.canvas.body.container.style.cursor = 'grab';
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText = "dragEnd event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
        // console.log("dragEnd Event:", params);
        // console.log(
        //     "dragEnd event, getNodeAt returns: " + this.getNodeAt(params.pointer.DOM)
        // );
    });
    network.on("controlNodeDragging", function (params) {
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText =
        //     "control node dragging event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
    });
    network.on("controlNodeDragEnd", function (params) {
        // params.event = "[original event]";
        // document.getElementById("eventSpanHeading").innerText =
        //     "control node drag end event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
        // console.log("controlNodeDragEnd Event:", params);
    });
    network.on("zoom", function (params) {
        // document.getElementById("eventSpanHeading").innerText = "zoom event:";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        // );
    });
    network.on("showPopup", function (params) {
        // document.getElementById("eventSpanHeading").innerText = "showPopup event: ";
        // document.getElementById("eventSpanContent").innerText = JSON.stringify(
        //     params,
        //     null,
        //     4
        //
        // );
    });
    network.on("hidePopup", function () {
        console.log("hidePopup Event");
    });
    network.on("select", function (params) {
        console.log("select Event:", params);
    });
    network.on("selectNode", function (params) {
        console.log("selectNode Event:", params);
    });
    network.on("selectEdge", function (params) {
        console.log("selectEdge Event:", params);
    });
    network.on("deselectNode", function (params) {
        console.log("deselectNode Event:", params);
    });
    network.on("deselectEdge", function (params) {
        console.log("deselectEdge Event:", params);
    });
    network.on("hoverNode", function (params) {
        network.canvas.body.container.style.cursor = 'pointer';
        console.log("hoverNode Event:", params);
    });
    network.on("hoverEdge", function (params) {
        console.log("hoverEdge Event:", params);
    });
    network.on("blurNode", function (params) {
        network.canvas.body.container.style.cursor = 'default';
        console.log("blurNode Event:", params);
    });
    network.on("blurEdge", function (params) {
        console.log("blurEdge Event:", params);
    });




    // network.setOptions({
    //     physics: {enabled:false}
    // });
    // network.stabilize(2000);

    document.getElementById("PreLoaderBar").style.display = "none";
    // console.log(network);


    go.count = 1;
    go(network);



}
function go(network) {
    // console.log("in the function")
    if (go.count < 9) {
        // logs 1, 2, 3 to firebug console at 1 second intervals
        // console.log(go.count++);
        window.setTimeout(go, 1000);
    }
    if(go.count === 9) {
        document.getElementById("PreLoaderBar").style.display = "none";
        // console.log(network);
        //console.log(network.getConnectedNodes())
    }

}

window.addEventListener("load", () => {
    draw()
    document.getElementById("text").innerText = "⌛"
});

